import React from 'react';
import { NavBarWrapper, NavList, NavItem, NavLink, SubMenu, SubMenuItem, SubNavLink } from './Header.style';

const Header = () => {
  return (
    <>
      <NavBarWrapper>
        <NavList>
          <NavItem>
            <NavLink href="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink>Text Modification/Formatting</NavLink>
            <SubMenu>
              <SubMenuItem>
                <SubNavLink href="/bold-text-converter">Bold Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/bubble-text-generator">Bubble Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/cursed-text-converter">Cursed Text Tool</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/duplicate-line-remover">Duplicate Line Remover</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/facebook-font-generator">Facebook Font Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/italic-text-converter">Italic Text Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/mirror-text-generator">Mirror Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/reverse-text-generator">Reverse Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/sentence-case">Sentence Case Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/small-text-generator">Small Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/strikethrough-text-generator">Strikethrough Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/superscript-generator">Superscript Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/title-case-converter">Title Case Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/underline-text-generator">Underline Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/unicode-text-converter">Unicode Text Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/upside-down-text-generator">Upside Down Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/whitespace-remover">Whitespace Remover</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/wide-text-generator">Wide Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/wingdings-converter">Wingdings Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/zalgo-glitch-text-converter">Zalgo Glitch Text Generator</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/json-escape">JSON Escape / Unescape</SubNavLink>
              </SubMenuItem>
              {/* <SubMenuItem>
                <SubNavLink href="/json-formatter">JSON Formatter</SubNavLink>
              </SubMenuItem> */}
            </SubMenu>
          </NavItem>
          <NavItem>
            <NavLink>Universal Converter Suite</NavLink>
            <SubMenu>
              <SubMenuItem>
                <SubNavLink href="/length-converter">Length Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/weight-converter">Weight Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/volume-converter">Volume Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/area-converter">Area Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/temperature-converter">Temperature Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/speed-converter">Speed Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/angle-converter">Angle Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/bytes-converter">Bytes/Bits Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/density-converter">Density Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/energy-converter">Energy Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/force-converter">Force Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/fuel-converter">Fuel Converter </SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/mass-converter">Mass Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/power-converter">Power Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/pressure-converter">Pressure Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/time-converter">Time Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/astronomical-converter">Astronomical Converter</SubNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <SubNavLink href="/frequency-converter">Frequency Converter</SubNavLink>
              </SubMenuItem>
            </SubMenu>
          </NavItem>
          <NavItem>
            <NavLink href="/about">About Us</NavLink>
          </NavItem>
        </NavList>
      </NavBarWrapper></>
  );
};

export default Header;
