import React, { useState } from 'react';
import TextAnalyzer from '../../../components/TextAnalyzer/TextAnalyzer'; // assuming you have a TextAnalyzer component
import { downloadText, copyToClipboard } from '../../../common/utils';
import { Container, CoffeeCup } from './JsonEscapeTextGenerator.style';
import { escapeJSONString, unescapeJSONString } from '../../../common/utils';
import FacebookShare from '../../../components/Media/FacebookShare';
import TwitterShare from '../../../components/Media/TwitterShare';
import PayPalDonate from '../../../components/Media/PaypalDonate';
import JsonEscapeTextMetaTags from '../../../components/MetaTag/JsonEscapeTextMetaTags';

const JsonEscapeTextGenerator = () => {
  const [textInput, setTextInput] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [transformedText, setTransformedText] = useState('');

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setTextInput(inputText);
  };

  const handleEscapeJSONString = (e) => {
    const convertedText = escapeJSONString(textInput);
    setTransformedText(convertedText);
  };

  const handleUnescapeJSONString = (e) => {
    const convertedText = unescapeJSONString(textInput);
    setTransformedText(convertedText);
  };

  const handleDownload = () => {
    downloadText(transformedText, 'jsonEscape.txt');
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(transformedText);

    // Set showMessage to true when the button is clicked
    setShowMessage(true);

    // Use setTimeout to set showMessage back to false after 5 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  };

  return (
    <Container>
      <JsonEscapeTextMetaTags />

      <div className='title'>
        <h1>JSON Escape / Unescape</h1>
        <p>
          <label htmlFor='content'>
            Removes characters from a JSON string that can cause parsing issues by either escaping or unescapes it.
          </label>
        </p>

        <ul>
          <li>
            <strong>Backspace</strong> is replaced with <strong>\b</strong>
          </li>
          <li>
            <strong>Form feed</strong> is replaced with <strong>\f</strong>
          </li>
          <li>
            <strong>Newline</strong> is replaced with <strong>\n</strong>
          </li>
          <li>
            <strong>Carriage return</strong> is replaced with <strong>\r</strong>
          </li>
          <li>
            <strong>Tab</strong> is replaced with <strong>\t</strong>
          </li>
          <li>
            <strong>Double quote</strong> is replaced with <strong>\"</strong>
          </li>
          <li>
            <strong>Backslash</strong> is replaced with <strong>\\</strong>
          </li>
        </ul>
      </div>
      {showMessage && <div className='success-message'>Text copied to clipboard!</div>}
      <div className='tool'>
        <div className='cols'>
          <div className='col'>
            <textarea
              id='content'
              placeholder='Copy-paste the JSON to escape or unescape here'
              autoFocus={true}
              onChange={handleInputChange}
            />
            <div className='actionBtns'>
              <button className='btn' onClick={handleEscapeJSONString} title='Escape the JSON string'>
                Escape JSON
              </button>
              <button className='btn' onClick={handleUnescapeJSONString} title='Unescape the JSON string'>
                Unescape JSON
              </button>
            </div>
          </div>
          <div className='col'>
            <div className='output'>
              <pre>{transformedText}</pre>
            </div>
            <div className='btns'>
              <button className='btn' onClick={handleDownload}>
                Download Text
              </button>
              <button className='btn' onClick={handleCopyToClipboard}>
                Copy to Clipboard
              </button>
              <a
                href='https://www.ko-fi.com/casemodify'
                className='btn-coffee'
                target='_blank'
                rel='noopener noreferrer'>
                <CoffeeCup src={'/icons/coffee-cup.png'} alt='Coffee Cup' /> Buy me a Coffee
              </a>
            </div>
          </div>
        </div>
      </div>
      <TextAnalyzer text={textInput} />
      <div className='media'>
        <FacebookShare />
        <TwitterShare />
        <PayPalDonate />
      </div>
    </Container>
  );
};

export default JsonEscapeTextGenerator;
